import { useCallback, useState } from 'react';

import { TextInput } from '../FormElements'
import api from '../../utils/api'
import { CopyButton } from '../Buttons';

export const CopyConstructButton = ({ construct }) => {

    const [ targetName, setTargetName ] = useState("")

    const handleNameChange = useCallback((val) => setTargetName(val), [])

    return (
        <CopyButton
            id={construct.id || construct.variable_lookup_id}
            targetParam={targetName}
            entityLabel="Custom Variable"
            copyFn={api.copyConstruct}
            modal={{
                title: "Copy Custom Variable",
                /* FIXME: there is presently *no way* to validate if
                        the user in question exists! */
                /*button: {
                    disabled: name !== construct.variable_name
                },*/
                body: (
                    <>
                        <p>Please type the username of the user you'd like to send a copy of this Custom Variable to.</p>
                        <TextInput
                            required
                            label="Username"
                            name="copy-to-username"
                            value={targetName}
                            onChange={handleNameChange}
                        />
                    </>
                ),
            }}
        />
    )
}
