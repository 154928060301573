//import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Select } from '../../components/FormElements';
import { useClinicalConstructContext } from "../../contexts";


export const ConstructMappingTable = ({readonly, title}) => {

    const [ valueMap, setValueMap ] = useState({});

    const { 
        variables,
        values,
        setConstraints,
    } = useClinicalConstructContext();
    
    // Set values/constraints in context
    useEffect(() => {
        if (!variables || variables.length !== 2) return;
        
        if (!variables[0].possible_values || !variables[1].possible_values) return;
        
        const complete = Object.keys(valueMap).filter(v => v.trim()).length === (variables[0].possible_values.length * variables[1].possible_values.length);
        
        if (!complete) return;

        // Wait to set until everything has been populated in table;
        // Form not valid until then anyway.
        // Rebuild whole constraints array every time because it's not that costly and 
        // it's easier than trying to keep track of all the individual parts

        // Make sure every index is represented! (Otherwise we get potential
        // Weird Stuff when it's time to save; see diver-issues#250)
        const newConstraints = values.map(() => {});

        Object.keys(valueMap).forEach((key, i) => {

            const varValues = key.split(',');
            const valIndex = parseInt(valueMap[key]);

            if (!newConstraints[valIndex]) {
                newConstraints[valIndex] = {
                    constraints: [],
                }
            } 

            newConstraints[valIndex].constraints.push({
                constraints: [{
                    variable_name: variables[0].variable_name,
                    constraint_type: 'EQ',
                    constraint_value: varValues[0],
                },
                {
                    variable_name: variables[1].variable_name,
                    constraint_type: 'EQ',
                    constraint_value: varValues[1],
                }],
                relationship: "AND",
            });

            if (newConstraints[valIndex].constraints.length > 1) {
                newConstraints[valIndex].relationship = "OR"
            }

        })
        
        setConstraints(newConstraints)
        
    }, [ setConstraints, values, variables, valueMap])


    const getCellValue = (var0Val, var1Val, returnidx) => {
        let foundValue = "";

        values.forEach(val => { 
            if (!val.definition) {
                return;
            }
            val.definition.constraints.forEach(constraintL1 => {
                if (constraintL1.constraints[0].constraint_value === var0Val 
                    && constraintL1.constraints[1].constraint_value === var1Val
                ) {
                    if (returnidx) {
                        foundValue = values.indexOf(val);
                    } else {
                        foundValue = val.name;
                    }
                    return;
                }
            })
        })

        return foundValue;
    }

    if ( variables.length < 2 || values.filter(v => v.name.trim()).length < 2) {
        return <></>; 
    }
    
    if (!variables[0].possible_values || !variables[1].possible_values) {
        return <></>;
    }


    return (

        <>
            {/* Title */}
            <h4 className="h6">
                { title || "Define Your New Values" }
            </h4>
            
            { !readonly ?
                <p>The value "[UNK]" (for "Unknown") is automatically provided for you. If you want to assign a value you did not already define, you can scroll up and add it. If you defined any values that end up not assigned in the table, you will have to scroll up and delete them before the "Save Custom Variable" becomes active.</p>
                : <></>}

            {/* Table */}
            <div className="table-scroll">
                <div className="table-scroll__wrapper">
                    <table className="w-100 table table--row-headers">
                        
                        <thead>

                            <tr>
                                <th className="table__blank-cell table__blank-cell--has-sibling"></th>
                                <th className="table__blank-cell"></th>

                                {/* First variable name header */}
                                <th colSpan={variables[0].value_mapping.length} className="span-header span-header--col">
                                    {variables[0].variable_name}
                                </th>
                            </tr>
                            
                            {/* First variable values header */}
                            <tr>
                                <th className="table__blank-cell table__blank-cell--has-sibling"></th>
                                <th className="table__blank-cell"></th>

                                
                                {
                                    variables[0].value_mapping.map((val, i) => (
                                        <th key={i}>
                                            {val}
                                        </th>
                                    ))
                                }
                            </tr>

                        </thead>

                        <tbody>
                    
                            {
                                variables[1].value_mapping.map((val1, i) => (
                                    <tr key={i}>

                                        {/* Second variable name "header" */}
                                        {i === 0 &&
                                            <th rowSpan={variables[1].value_mapping.length} className="span-header span-header--row">
                                                <div>{variables[1].variable_name}</div>
                                            </th>
                                        }

                                        {/* Second variable values "header" (done one at a time since we're iterating over second variable values) */}
                                        <th>
                                            {val1}
                                        </th>
                                    
                                        {
                                            variables[0].value_mapping.map((val0, j) => (
                                                <td key={j}>

                                                    { readonly ?
                                                        getCellValue(variables[0].possible_values[j],  variables[1].possible_values[i])
                                                    :
                                                        <Select
                                                            label={`Value for ${variables[0].variable_name} = ${val0} and ${variables[1].variable_name} = ${val1}`}
                                                            hideLabel
                                                            name={val0 + ',' + val1}
                                                            onChange={(v) => {
                                                                setValueMap({
                                                                    ...valueMap,
                                                                    [variables[0].possible_values[j] + ',' + variables[1].possible_values[i]]: v
                                                                })
                                                            }}
                                                            value={valueMap[variables[0].possible_values[j] + ',' + variables[1].possible_values[i]] || getCellValue(variables[0].possible_values[j],  variables[1].possible_values[i], true)}
                                                            options={[
                                                                {name: "--", value: ""},
                                                                ...values.filter(v=>v).map(( v, i ) => ({
                                                                    name: v.name,
                                                                    value: i,
                                                                }))
                                                            ]}
                                                        />
                                                    }

                                                </td>
                                            ))
                                        }

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )

}

/*ConstructMappingTable.propTypes = {
    readonly: PropTypes.bool,
    title: PropTypes.string,
}*/
