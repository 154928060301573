import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Cohort, CohortTree } from '../../components/Cohorts';
import { Select } from '../../components/FormElements';
import { Spacer } from '../../components/GeneralUI';
import { CreateCohortPage } from '../../layout';
import { useAppState/*, useCancelAsyncCall, usePrevious*/ } from '../../hooks';

import { getPathFromRouteName, getPlainLangCompoundRelationship, parseCohortListToTree } from '../../utils';
import api from '../../utils/api';
import { COHORT_LOGIC, VIEW_COHORT } from '../../utils/constants';

const initialResults = []

const relationshipOptions = ["UNION","SUBTRACT","INTERSECT",]

export const CreateCompoundCohort = () => {
    
    const [ relationship, setRelationship ] = useState(relationshipOptions[0])
    const [ cohort, setCohort ] = useState(null)
    const [ secondCohort, setSecondCohort ] = useState(null)
    const [ cohorts, setCohorts ] = useState([])
    const [ results, setResults ] = useState(initialResults)
    const [ cohortSaving, setCohortSaving ] = useState(false)

    const [ name, setName ] = useState('')
    const [ description, setDescription ] = useState('')

    const navigate = useNavigate();

    const { id } = useParams();

    //const prevSecondCohort = usePrevious(secondCohort);
    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText } = useAppState();

    // Get pre-selected cohort
    useEffect(() => {
        const searchId = id || 1;
       
        api.getCohort(searchId)
            .then(res => {
                //if (cancelAsyncCall()) return null;
                setCohort(res.data.cohorts[0]);
            })
            .catch(err => {
                toast.error(errorCheckText(err, `Unable to get selected cohort with id ${id}.`))
            })

    }, [/* cancelAsyncCall,*/ id, errorCheckText ])


    useEffect(() => {
        api.getAllCohorts()
            .then(res => {
                //if (cancelAsyncCall()) return null;
                const cohorts = res.data.cohorts;
   
                let parsedCohorts;
                try {
                    parsedCohorts = parseCohortListToTree(cohorts);
                } catch(e) {
                    console.log(e)
                }
  
                setCohorts(parsedCohorts);
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get Cohorts.'))
            })

    }, [ /*cancelAsyncCall, */errorCheckText ])


    const handlePreviewResults = useCallback(() => {

        api.previewCohort({
            parent_id: id,
            cohort_type: relationship, 
            secondparent_id: secondCohort.cohortid,
        })
        .then(res => {
            setResults(res.data);
        })
        .catch(err => {
            toast.error(errorCheckText(err, 'Unable to preview Cohort.'))
        })
    }, [id, secondCohort, relationship, errorCheckText])

    useEffect(() => {
        // Only take action when second cohort has changed.
        // Otherwise `handlePreviewResults` will cause this to rerun whenever
        // its dependencies change
            if (secondCohort) {
                handlePreviewResults();
            } else {
                // If the second cohort gets deleted (e.g. to choose a new one) reset results 
                setResults(initialResults)
            }
    }, [handlePreviewResults, secondCohort])

    const cohortActions = {
        primary: [
            {
                name: 'compound',
                onClick: cohort => {
                    const cohortCopy = {...cohort}
                    delete cohortCopy.children;
                    setSecondCohort(cohortCopy)
                },
                tooltip: 'Select to Relate Cohorts',
                iconName: "relation-" + relationship.toLowerCase(),
                variant: 'secondary',
                fontAwesome: false,
            },
        ],
        secondary: [
            {
                name: 'logic',
                to: getPathFromRouteName(COHORT_LOGIC),
                tooltip: 'View Logic',
                iconName: 'expanded-detail',
                fontAwesome: false,
                newTab: true,
            },
            {
                name: 'view',
                to: getPathFromRouteName(VIEW_COHORT),
                tooltip: 'View Details',
                iconName: 'table',
                newTab: true,
            },
        ]
    }


    const handleSave = () => {
        setCohortSaving(true);

        api.createCohort({
                name: name, 
                description: description,
                parent_id: id, 
                cohort_type: relationship, 
                secondparent_id: secondCohort.cohortid,
            })
            .then(res => {
                const id = res.data.new_cohort_id;
                setCohortSaving(false);
                navigate(getPathFromRouteName(VIEW_COHORT, {id}))
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to save Cohort.'))
                setCohortSaving(false);
            })

    }



    return (
        
        <CreateCohortPage
            title="Create Compound Set"
            intro="A Compound Set Cohort is a new Cohort created from two existing Cohorts - either by combining those Cohorts, removing the individuals in one Cohort from the other, or by only picking those individuals common to both Cohorts."
            formProps={{
                noParent: true,
                name,
                description,
                setName,
                setDescription,
            }}
            onSave={handleSave}
            recordsCondition={!!(secondCohort && results)}
            results={results}
            cohortSaving={cohortSaving}
        >

            <section>

                <h3 className="h5">Select Relationship</h3>

                <div className="cohorts cohorts--relate">
                    
                    {/* First/"parent" cohort */}
                    <Cohort 
                        indent
                        cohort={cohort} 
                        noDelete
                    />

                    {/* Relationship selector */}
                    <Select
                        name="relationship"
                        label="Relationship"
                        hideLabel
                        classes="cohort__relate"
                        onChange={v => setRelationship(v)}
                        options={relationshipOptions.map(o => ({
                            value: o,
                            name: getPlainLangCompoundRelationship(o)
                        }))}
                        value={relationship}
                    />

                    { secondCohort ?

                        // Second cohort
                        <Cohort 
                            indent
                            cohort={secondCohort} 
                            noDelete
                            actions={{
                                primary: [{
                                    name: "remove",
                                    tooltip: "Choose a different Cohort",
                                    onClick: () => setSecondCohort(null),
                                    iconName: "trash-alt",
                                }]
                            }}
                        />

                    :
                        // Empty/placeholder second cohort
                        <Cohort 
                            indent
                            cohort={null} 
                            text={<div className="font-italic">Select a cohort below</div>}
                        />

                    }

                </div>


                <Spacer size="3" />


                {/* Choose the Cohort */}
                {
                    !secondCohort &&
                        <>
                            <h4 className="h6">One cohort selected. Add another to create a new cohort from the related ones.</h4>

                            <CohortTree 
                                cohorts={cohorts} 
                                cohortActions={cohortActions}
                            />
                        </>
                }

            </section>

        </CreateCohortPage>

    )
}
