import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { CohortLogicGroup } from "../../components/Cohorts"
import { useAppState/*, useCancelAsyncCall*/ } from "../../hooks"
import { InnerPage } from "../../layout"
import { parseCohortOfInterestTree } from "../../utils"
import api from "../../utils/api"

export const CohortLogic = () => {

    const [ allCohorts, setAllCohorts ] = useState([]);
    const [ cohort, setCohort ] = useState({})
    const [ childCohortRefs, setCreateSubsetCohortRefs ] = useState([null]);
    const [ logicTree, setLogicTree ] = useState([]);

    const { id } = useParams();
    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText } = useAppState();

    useEffect(() => {

        api.getAllCohorts()
            .then(res => {
                //if (cancelAsyncCall()) return null
               
                const cohorts = res.data.cohorts;

                const cohort = cohorts.find(c => c.cohortid === parseInt(id))
                setCohort(cohort)

                const cohortTree = parseCohortOfInterestTree(cohorts, cohort);

                setAllCohorts(cohorts)
                
                // a quirk of the logic tree is that the whole tree is an array and each of the items is too
                setLogicTree([[cohortTree]])

            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get Cohorts.'))
            })
       
    }, [/*cancelAsyncCall, */id, errorCheckText])

    const addCohortGroup = (parentCohort, ref, index) => {
        
        // this updates the existing logicTree in place, unless we clone deeper;
        const allCohortsClone = allCohorts.map(c =>({...c}));
        const parentLogicTree = parseCohortOfInterestTree(allCohortsClone, parentCohort);
        
        // a quirk of the logic tree is that the whole tree is an array and each of the items is too
        const parentLogicTreeGroup = [ parentLogicTree ]

        const newLogicTree = [...logicTree]
        const newCreateSubsetCohortRefs = [...childCohortRefs]

        newLogicTree.splice(index + 1, logicTree.length - index, parentLogicTreeGroup );  

        newCreateSubsetCohortRefs[index + 1] = ref;

        setLogicTree(newLogicTree)

        setCreateSubsetCohortRefs(newCreateSubsetCohortRefs)
    }


    // maintain order, so that key stays the same -> 
    // avoid unnecessary rerenders of children; reverse when displayed, so "0" is at the bottom
    const renderCohortLogicGroups = () => {
        return logicTree.map((cohorts, i) => (

            <CohortLogicGroup 
                key={i}
                index={i}
                cohorts={cohorts}
                childCohortRef={childCohortRefs[i]}
                addCohortGroup={addCohortGroup}
                includeArrow={i !== 0}
            />
         ))
    }

    return (
        <InnerPage
            title={`Cohort Logic for ${cohort.name}`}
            intro="Instructional text if necessary. [./pages/Cohorts/CohortLogic.js, line 212] ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sem nulla pharetra diam sit amet. Consequat id porta nibh venenatis."
        >

            {
                renderCohortLogicGroups().reverse()
            }         
         
        </InnerPage>
    )
}
