// All can be "Unknown" or undefined in addition to unique values
export const VARIABLE_TYPES = {
    // integer, but one that maps to a string
    CATEGORICAL: "categorical",
    // boolean (or "Unknown")
    DICHOTOMOUS: "dichotomous",
    // YYYYMMDD. U can be substituted for unknown portion of date. (Or "Unknown")
    DATE: "date",
    // like categoical, but can pick more than one
    MULTICATEGORY: "multicategory",
    // Integer (or "Unknown")
    NUMBERRANGE: "numberrange",
    // Free-form, of any length
    TEXT: "text",
    // Type not yet defined in database; treat as text
    NULL: "null",
}

