import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';

import { VariablesSelect } from '../../components/Variables';
import { CreateCohortPage } from "../../layout"
import { ConstrainVariable } from '../../components/Constraints';

import { getPathFromRouteName } from "../../utils";
import api from "../../utils/api";
import { VIEW_COHORT } from "../../utils/constants";
import { useAppState } from "../../hooks";

const initialResults = []

export const AscertainPedigrees = () => {

    const [ variable, setVariable ] = useState(null)
    const [ results, setResults ] = useState(initialResults)
    const [ name, setName ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ constraint, setConstraint ] = useState(null)
    const [ previewLoading, setPreviewLoading ] = useState(false)
    const [ cohortSaving, setCohortSaving ] = useState(false)
    
    const { id } = useParams();
    const navigate = useNavigate();
    const { errorCheckText } = useAppState();

    // If the variable gets deleted (e.g. to choose a new one) reset results 
    useEffect(() => {
        if (!variable) {
            setResults(initialResults)
        }
    }, [variable])

    const variableActions = [
        {
            onClick: setVariable,
            tooltip: 'Select and constrain',
            iconName: 'plus-circle',
            variant: 'secondary'
        }
    ]

    const handlePreviewResults = (constraint) => {
        // set this now so it can be used when saving (passed to CreateCohortPage)
        setConstraint(constraint)
        setPreviewLoading(true)

        api.previewCohort({
                parent_id: id,
                variable: variable.variable_name,
                ...constraint // constraint_value/constraint_values and cohort_type
            })
            .then(res => {
                setResults(res.data);
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to preview Pedigree Set.'))
            })
            .finally(() => {
                setPreviewLoading(false)
            })
    }

    const handleSave = () => {
        setCohortSaving(true);
        
        api.createCohort({
                parent_id: id,
                name,
                description,
                variable: variable.variable_name,
                ...constraint // constraint_value/constraint_values and cohort_type
            })
            .then(res => {
                const id = res.data.new_cohort_id;
                setCohortSaving(false);
                navigate(getPathFromRouteName(VIEW_COHORT, {id}))
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to save Pedigree Set.'))
                setCohortSaving(false);
            })

    }

    return (
        <CreateCohortPage
            title="Ascertain Pedigrees"
            intro="Ascertaining Pedigrees involves creating an expanded Cohort (based on an existing one) by adding related individuals, potentially based on simple constraints for those related individuals."
            formProps={{
                parentId : id,
                name,
                description,
                setName,
                setDescription,
            }}
            onSave={handleSave}
            recordsCondition={!!constraint}
            results={results}
            previewLoading={previewLoading}
            cohortSaving={cohortSaving}
            isPedigreeForm
        >

            <VariablesSelect
                variableActions={variableActions} 
                hide={!!variable} // just hide this when a variable is chosen; don't unmount, so options remain selected
            />

            {
                !!variable &&
           
                    <ConstrainVariable 
                        variable={variable} 
                        deleteVariable={() => setVariable(null)} 
                        onSubmit={handlePreviewResults} 
                        isPedigreeForm
                    />

            } 
         
        </CreateCohortPage>
    )
}
