import { useEffect, useState } from "react"
import { toast } from "react-toastify";
//import PropTypes from "prop-types";

import { VariablesTable } from "../Variables";
import api from '../../utils/api'
import { useAppState/*, useCancelAsyncCall*/ } from "../../hooks";

/**
 * Displays custom variables in a table
 */
export const CustomVariables = ({ noTitle, hide, variableActions }) => {

    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText } = useAppState();

    const [ variables, setVariables ] = useState([]);

    // Get custom variables from API and set them in state
    useEffect(() => {
        
        api.getCustomVariables()
            .then(res => {
                //if (cancelAsyncCall()) return null
                setVariables(res.data.custom_vars)
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get custom Variables.'))
            })
        

    }, [/*cancelAsyncCall, */errorCheckText])

    return (
        <section className={hide ? 'd-none' : ''}>

            {!noTitle && <h3 className="h5">My Custom Variables</h3>}

            <VariablesTable
                id="custom-variables-table"
                variables={variables}
                variableActions={variableActions} 
            />

        </section>
    )
}


/*CustomVariables.propTypes = {
    hide: PropTypes.bool,
    variableActions: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.object,  PropTypes.func])
    ),
}*/
