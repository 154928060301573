import { Fragment } from "react"
//import PropTypes from "prop-types";

import { useClinicalConstructContext } from "../../contexts";
import { getPlainLangVariableConstraint } from "../../utils";

export const MultiLineConstraintReadonly = ({
    constraintGroup,
    gutterSize,
    index,
}) => {

    const { variables } = useClinicalConstructContext();

    const { constraints } = constraintGroup;

    const variable = (constraintGroup.constraints[0] && variables.find(v => v.variable_name === constraintGroup.constraints[0].variable_name)) || variables[0];

    if (!variable) return '';

    return (
        <div className="row">

            <div className={`px-${gutterSize}`}> 
                
                {/* Display variable thate constrainted this group  */}
                <div className="font-weight-bold">{variable.variable_name}</div>

            </div>

            {/* Each of these is a "row" in the complex constraint */}
            {constraints.map((c, i) => (
                <Fragment key={i}>
                    {

                        i !==0 &&

                            <div className={`align-items-center d-flex pl-5 pr-${gutterSize}`}> 
                                
                                {/* Display the relationship for this group, i.e. AND/OR */}
                                {constraintGroup.relationship}
                            
                            </div>    
                        
                    }



                    {/* The actual constraint definition */}
                    <div>{getPlainLangVariableConstraint(c.constraint_type, c.constraint_value)}</div>

                    {/* For spacing between rows */}
                    {i !== constraints.length - 1 &&
                        <div className="col-12 mb-3"></div>
                    }

                </Fragment>
            ))}
    
        </div>
    )
}

/*MultiLineConstraintReadonly.propTypes = {
    constraintGroup: PropTypes.object.isRequired,
    gutterSize: PropTypes.number,
    index: PropTypes.number.isRequired,
}*/
