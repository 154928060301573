import { useEffect, useRef, useState } from "react";
//import PropTypes from "prop-types";

import { VariableDefaultActions } from ".";
import { IconButton } from "../Buttons"
//import { variableShape } from "../../utils";


export const VariableTableRow = ({ variable, actions = [], colsDef, updateRowHeight }) => {

    const rowRef = useRef();
    const stickyCellRef = useRef();

    const [ rowHeight, setRowHeight ] = useState();

    useEffect(() => {
        setRowHeight(Math.max(rowRef.current.clientHeight, stickyCellRef.current.clientHeight));
    }, [rowRef, stickyCellRef, variable, updateRowHeight])

    const setStickyHeight = () => {
        if (!rowHeight) {
            return null;
        }
        let height = rowHeight - 1 

        if (height < 61) {
            height = 61;
        } 

        return {height};
    }


    return (
        <tr ref={rowRef} style={rowHeight < 62 ? {height: 62} : {height:rowHeight}}>
            {/* Bulk of the columns are handled with the below */}
            {colsDef.map((column, i) => {
                return <td key={i}>{column.xform(variable)}</td>
            })}
            <td key="99" ref={stickyCellRef} className="sticky-cell" style={setStickyHeight()}>
                <div className="icon-btn-group align-items-center justify-content-end icon-btn-group">

                    <VariableDefaultActions variable={variable} />

                    {
                        actions.map((action, i) => {
                            let to = '';
                            let onClick = null;

                            if (typeof action === 'function') {
                                action = action(variable);
                            }

                            if (action.to) {
                                to = action.to.replace(':id',
                                    variable.variable_name)
                            } else if (action.onClick) {
                                onClick = () => action.onClick(variable)
                            }
                            return <IconButton 
                                key={i} 
                                {...action} 
                                to={to} 
                                onClick={onClick} 
                                variant={action.variant ? action.variant : "outline"}
                            />
                        })
                    }
                </div>
            </td>
        </tr>
    )
}

/*VariableTableRow.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.object,  PropTypes.func])
    ),
    updateRowHeight: PropTypes.bool,
    colsDef: PropTypes.arrayOf(PropTypes.object),
    variable: PropTypes.shape(variableShape),
    
}*/
