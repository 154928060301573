import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { VariableCollectionForm, VariablesSelect } from '../../components/Variables';
import { Alert, Icon, Spacer } from '../../components/GeneralUI';
import { Button, DeleteButton } from '../../components/Buttons';
import { InnerPage } from '../../layout';
import api from '../../utils/api'
import { MY_VARIABLE_SETS } from '../../utils/constants';
import { useAppState/*, useCancelAsyncCall*/ } from '../../hooks';


export const ViewVariableSet = () => {

    const [ description, setDescription ] = useState('');
    const [ editing, setEditing ] = useState(false);
    const [ name, setName ] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [ variables, setVariables ] = useState([]);
    const [ variableSet, setVariableSet ] = useState({});
    
    const { id } = useParams();
    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText } = useAppState();

    // Get the Variable Set and set up data from it in state
    useEffect(() => {
      
        api.getVariableSet(id)
            .then(res => {
                //if (cancelAsyncCall()) return null;

                const variableSet = res.data.varsets[0];
                setVariableSet(variableSet)
                setName(variableSet.name)
                setDescription(variableSet.description)
                setVariables(variableSet.variables)
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get Variable Set.'));
            })
            .finally(() => setLoading(false))

    }, [/*cancelAsyncCall, */id, errorCheckText])

    const addVariableToSet = (variable) => {
        const newVars = [...variables];
        newVars.push(variable);
        setVariables(newVars);
    }

    const removeVariableFromSet = (variable) => {
        const newVars = [...variables];
        const foundIndex = newVars.findIndex(v => v.variable_name === variable.variable_name);
        newVars.splice(foundIndex, 1);
        setVariables(newVars);
    }

    const removeVariableActionProps = {
        onClick: removeVariableFromSet,
        tooltip: 'Remove from Variable Set',
        iconName: 'check-square',
        variant: 'secondary'
    } 
    const addVariableActionProps = {
        onClick: addVariableToSet,
        tooltip: 'Add to Variable Set',
        iconName: 'square',
        iconWeight: 400,
        variant: 'secondary',
    }

    const variableSearchResActions = [
        (variable) => {
            // if variable is in the selected ones, the button will remove it
            if (variables.findIndex(v => v.variable_name === variable.variable_name) > -1) {
                return removeVariableActionProps
            }
            // otherwise, the button will add it
            return addVariableActionProps;
        }
    ]

    const variableCollectionActions = !editing ? [] :  [ removeVariableActionProps ]

    // Submit edit form
    const handleSubmit = () => {
        if (name) {
            // Need to translate variables to names only first
            let varnames = variables.map(v => v.variable_name);
            let varsetid = id;
            
            api.updateVariableSet({
                    name,
                    description,
                    varsetid,
                    'variables': varnames,
                })
                .then(res => {
                    
                    setEditing(false)
                    toast.success('Variable Set saved.')

                    // set with new values
                    api.getVariableSet(id)
                        .then(res => {
                            setVariableSet(res.data.varsets[0])
                        })
                        .catch(err => {
                            toast.error(errorCheckText(err, 'Error updating page. Please reload to see changes.'));
                        })
                        
                })
                .catch(err => {
                    toast.error(errorCheckText(err, 'Unable to save Variable Set.'));
                });
        }
        
    }

    // Reset form when editing is canceled
    const handleReset = () => {
        setEditing(false)
        setVariables(variableSet.variables)
    }

    if ( !variableSet || !variableSet.name ) {
        return (
            <InnerPage
                loading={loading}
                title="View Variable Set"
            >

                <Alert variant="warning">No cohort was found with the id "{id}"</Alert>

            </InnerPage>
        )
    }

    return (

        <InnerPage
            title={variableSet.name} 
            editButton={<Button classes="btn--text-link" onClick={() => setEditing(true)}>Edit <Icon name="pencil-alt" /></Button> }
            intro={variableSet.description}
            loading={loading}
            actions={
                <div className="icon-btn-group">
                    <DeleteButton
                        id={variableSet.id}
                        entityLabel="Variable Set"
                        deleteFn={api.deleteVariableSet}
                        modal={{
                            title: "Delete Variable Set?",
                            body: "Please confirm that you’d like to delete this Variable Set."
                        }}
                        returnRoute={MY_VARIABLE_SETS}
                    />
                </div>

            }
        >

            <VariableCollectionForm
                name={name}
                description={description}
                variables={variables}
                componentLabel="Variable Set"
                editable={editing}
                variableActions={variableCollectionActions}
                setName={editing ? setName : null}
                setDescription={editing ? setDescription : null}
                onReset={editing ? handleReset : null}
                onSubmit={editing ? handleSubmit : null}
                disableSave={!variables.length || !name}
            />

            <Spacer size={3} />

            { editing &&

                <VariablesSelect
                    variableActions={variableSearchResActions}
                />
                
            }

        </InnerPage>
    
    )
}
