//import PropTypes from "prop-types"

import { CONSTRAINT_TYPES, VARIABLE_TYPE_CONSTRAINTS } from "../../utils/constants/constraints"
import { Select } from "../FormElements"

/**
 * Select element for choosing type of constraint, i.e. "Is equal to", "Exists"
 * based on the variable's data type, i.e. dichotomous, null
 */
export const SelectConstraintType = ({ constraintType, dataType, setConstraintType, isPedigreeForm }) => {
    
    return (

        <Select
            name="constraint_type"
            label="Constraint Type"
            hideLabel
            onChange={setConstraintType}
            value={constraintType} 
            options={
                isPedigreeForm ?
                VARIABLE_TYPE_CONSTRAINTS["PedigreeForm"].map((opt, i) => (
                    {
                        name: CONSTRAINT_TYPES[opt].label,
                        value: opt
                    }
                ))
                :
                VARIABLE_TYPE_CONSTRAINTS[dataType].map((opt, i) => (
                    {
                        name: CONSTRAINT_TYPES[opt].label,
                        value: opt
                    }
                ))
            }
        />

    )
}

/*SelectConstraintType.propTypes = {
    dataType: PropTypes.string.isRequired,
    constraintType: PropTypes.string.isRequired,
    setConstraintType: PropTypes.func.isRequired,
    isPedigreeForm: PropTypes.bool,
}*/
