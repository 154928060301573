import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CohortForm, CohortPreviewSummaries } from '../components/Cohorts';
import { Count } from '../components/Typography';
import { Icon, Spacer, LoadingWrapper } from '../components/GeneralUI';
import { InnerPage } from '.';
import { Button } from '../components/Buttons';
//import PropTypes from 'prop-types';


export const CreateCohortPage = ({
    children,
    formProps,
    intro,
    onSave,
    title,
    recordsCondition = true,
    results,
    previewLoading,
    cohortSaving,
    isPedigreeForm = false,
}) => {

    const [ saveEnabled, setSaveEnabled ] = useState(false)

    const { id } = useParams();

    const handleSave = () => {
        
        // Common actions go here, if any

        if (onSave)
            onSave();
    }

    useEffect(() => {
        const resultsCount = results ?
                isPedigreeForm ?
                    results.reduce(
                        (total, group) => total + group.pedigree_count, 0)
                :
                    results.reduce(
                        (total, group) => total + group.count, 0)
                : 0

        if ((resultsCount > 0) && (formProps.name || formProps.description) && id) {
            setSaveEnabled(true)
        } else {
            setSaveEnabled(false)
        }

    }, [  id, results, formProps, isPedigreeForm ])


    return (
        
        <InnerPage
            title={title}
            intro={intro}
            sticky
            actions={
                <Button
                    onClick={handleSave}
                    variant="secondary"
                    disabled={!saveEnabled}
                    buttonLoading={cohortSaving}
                >
                    <span className="btn--text">
                        Save {isPedigreeForm ? "Pedigree Set" : "Cohort"} <Icon name="check-circle" />
                    </span>
                </Button>
            }
        >
            <CohortForm 
                {...formProps}
            />

            <Spacer size={3} />

            {children}

            {

                recordsCondition &&

                <section>
                    <Spacer size={3} />
                    {/* Can't do the below yet because summarize-by-pedigree is not yet feasible, alas */}
                    <h3 className="h5">Summary Of {isPedigreeForm ? "Pedigrees" : "Individuals"} In Data Set <Count>{
                    results ?
                            isPedigreeForm ?
                                results.reduce(
                                    (total, group) => total + group.pedigree_count, 0)
                            :
                                results.reduce(
                                    (total, group) => total + group.count, 0)
                            : 0}</Count></h3>
                    <LoadingWrapper loading={previewLoading}>
                        <CohortPreviewSummaries
                            records={results}
                            isPedigreeForm={isPedigreeForm}
                        />
                    </LoadingWrapper>
                </section>
           
            }

        </InnerPage>

    )
}

/*CreateCohortPage.propTypes = {
    children: PropTypes.node.isRequired,
    formProps: PropTypes.shape({
        parentId: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        noParent: PropTypes.bool,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        setName: PropTypes.func.isRequired,
        setDescription: PropTypes.func.isRequired,
    }),
    intro: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    recordsCondition: PropTypes.bool,
    results: PropTypes.array,
    isPedigreeForm: PropTypes.bool,
}*/
