//import PropTypes from "prop-types";

import { Cohort } from "."
//import { cohortShape } from "../../utils";

/**
 * Part of Cohort Logic page; displays a single group of one cohort's logic;
 */
export const CohortLogicGroup = ({
    childCohortRef, // childCohortRef passes a reference to the cohort *outside* this group whose button was clicked
    cohorts, 
    addCohortGroup: _addCohortGroup,
    includeArrow,
    index,
}) => { 
    
    const addCohortGroup = (parentCohort, cohortRef) => {
        // cohortRef passes a reference to the cohort *within* this group whose button was clicked
        _addCohortGroup(parentCohort, cohortRef, index);
    }
 

    return (
        <>
            
            <div className={includeArrow ? "pb-5" : ''}>

                {cohorts.map((cohort, j) => (
                    <Cohort
                        key={cohort.cohortid} 
                        cohort={cohort}
                        actions={{
                            primary: [
                                {
                                    name: "logic",
                                    onClick: addCohortGroup,
                                    iconName: 'expanded-detail',
                                    tooltip: 'View Logic',
                                    variant: 'secondary',
                                    fontAwesome: false,
                                }
                            ]
                        }}
                        includeArrow={includeArrow && j === cohorts.length - 1}
                        childCohortRef={childCohortRef}
                    />
                ))}

            </div>

        </>        
    )
}

/*CohortLogicGroup.propTypes = {
    addCohortGroup: PropTypes.func,
    childCohortRef: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.any })
    ]),
    cohorts: PropTypes.arrayOf(PropTypes.shape(cohortShape)),
    includeArrow: PropTypes.bool,
    index: PropTypes.number,
}

*/
