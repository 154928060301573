import { useEffect, useState } from "react";
//import PropTypes from "prop-types";

import { VariablesSearch } from "./";
import { CustomVariables } from "../ClinicalConstructs";
import { RadioInput } from "../FormElements";
import { Button } from "../Buttons";

export const VariablesSelect = ({ noTitle, handleClose, hide, variableActions }) => {

    const [ selectType, setSelectType ] = useState('search');
    const [ searchHide, setSearchHide ] = useState();
    const [ constructsHide, setConstructsHide ] = useState();
    
    // switch forms when selectType is set
    useEffect(() => {
        if (selectType === "search") {
            setConstructsHide(true);
            setSearchHide(false);
        } else {
            setSearchHide(true);
            setConstructsHide(false);
        }
    }, [selectType, setConstructsHide, setSearchHide])

    return (
        <section className={hide ? 'd-none' : ''}>
            {!noTitle && <h3 className="h5 mb-0 mr-3">Select Variables</h3> }
            {handleClose && <Button variant="primary" size="xs" onClick={handleClose}>Close</Button> }
            
                <div className="row">
                    <div className="col-12 py-2">
                        
                        <RadioInput
                            name="search"
                            onChange={setSelectType}
                            selectedOption={selectType}
                            value="search"
                            label="Search Variables Catalog by Keyword or Variable Name"
                        />
                        <RadioInput
                            name="construct"
                            onChange={setSelectType}
                            selectedOption={selectType}
                            value="construct"
                            label="Select one of your Custom Variables"
                        />
                    </div>
                </div>
            
            <VariablesSearch
                variableActions={variableActions}
                noTitle
                hide={searchHide}
            />
            
            <CustomVariables
                variableActions={variableActions}
                noTitle
                hide={constructsHide}
            />
        </section>
    )
}

/*VariablesSelect.propTypes = {
    hide: PropTypes.bool,
    variableActions: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.object,  PropTypes.func])
    ),
}*/
