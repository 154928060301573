import { useCallback, useState } from 'react';

import { TextInput } from '../FormElements'
import api from '../../utils/api'
import { DeleteButton } from '../Buttons';

/**
 * Delete button + confirmation modal for deleting cohorts
 */
export const DeleteCohortButton = ({ cohort, returnRoute }) => {

    const [ name, setName ] = useState("")

    const handleNameChange = useCallback((val) => setName(val), [])

    return (
        <DeleteButton
            id={cohort.cohortid}
            entityLabel="Cohort"
            deleteFn={api.deleteCohort}
            returnRoute={returnRoute}
            modal={{
                title: "Delete Cohort?",
                button: {
                    disabled: name !== cohort.name
                },
                body: (
                    <>
                        <p>Deleting this cohort will also delete any descendant cohorts.</p>
                        
                        <p>Please confirm that you’d like to delete <strong>{cohort.name}</strong> by typing the Cohort name <strong>{cohort.name}</strong> below.</p>
                        

                        <TextInput
                            required
                            label="Cohort Name"
                            value={name}
                            name="delete-confirm-name"
                            onChange={handleNameChange}
                        />
                    </>
                ),
            }}
        />
    )
}
