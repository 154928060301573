import { useEffect, useState } from 'react';

import { VariablesSelect, VariableCollectionForm } from '../../components/Variables';
import { InnerPage } from '../../layout';
import { Icon, Spacer } from '../../components/GeneralUI';
import { Button } from '../../components/Buttons';

import api from '../../utils/api'
import { useNavigate } from 'react-router';
import { getPathFromRouteName } from '../../utils';
import { VIEW_VARIABLE_SET } from '../../utils/constants';
import { toast } from 'react-toastify';
import { useAppState } from "../../hooks";


export const NewVariableSet = () => {

    const [ variables, setVariables ] = useState([]);
    const [ saveEnabled, setSaveEnabled ] = useState(false);
    const [ varSetSaving, setVarSetSaving ] = useState(false);
    const [ name, setName ] = useState("");
    const [ description, setDescription ] = useState("");

    const navigate = useNavigate();
    const { errorCheckText } = useAppState();

    const addVariableToSet = (variable) => {
        const newVars = [...variables];
        newVars.push(variable);
        setVariables(newVars);
    }

    const removeVariableFromSet = (variable) => {
        const newVars = [...variables];
        const foundIndex = newVars.findIndex(v => v.variable_name === variable.variable_name);
        newVars.splice(foundIndex, 1);
        setVariables(newVars);
    }

    const removeVariableActionProps = {
        onClick: removeVariableFromSet,
        tooltip: 'Remove from Variable Set',
        iconName: 'check-square',
        variant: 'secondary'
    } 
    const addVariableActionProps = {
        onClick: addVariableToSet,
        tooltip: 'Add to Variable Set',
        iconName: 'square',
        iconWeight: 400,
        variant: 'secondary',
    }

    // Action in the search results table
    const variableSearchResActions = [
        (variable) => {
            // if variable is in the selected ones, the button will remove it
            if (variables.findIndex(v => v.variable_name === variable.variable_name) > -1) {
                return removeVariableActionProps
            }
            // otherwise, the button will add it
            return addVariableActionProps;
        }
    ]

    // Action in the variable set collection form
    const variableCollectionActions = [ removeVariableActionProps ]


    // Enable/disable the Save button, when variables are added/removed to the Variable Set
    useEffect(() => {
        if (variables.length && name) 
        {
            setSaveEnabled(true);
        }
        else {
            setSaveEnabled(false)
        }
    }, [variables, name])

    const handleSave = () => {
        setVarSetSaving(true);

        // Gather data to send to API
        const variableIds = variables.map(v => v.variable_name);

        // Send data to API 
        api.createVariableSet({
                name,
                description,
                variables: variableIds,
            },)
            .then(res => {
                const variableSet = res.data;
                setVarSetSaving(false);
                navigate( getPathFromRouteName(VIEW_VARIABLE_SET, { id : variableSet.id }) )
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to save Variable Set.'));
                setVarSetSaving(false);
            })
    }

    return (

        <InnerPage
            sticky
            title="Define a new Variable Set"
            actions={
                <Button
                    variant="secondary"
                    disabled={!saveEnabled}
                    onClick={handleSave}
                    buttonLoading={varSetSaving}
                >
                    <span className="btn--text">
                        Save Variable Set <Icon name="check-circle" />
                    </span>
                </Button>
            }
        >

            <VariableCollectionForm
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}
                variables={variables}
                componentLabel="Variable Set"
                variableActions={variableCollectionActions}
            />

            <Spacer size={3} />

            <VariablesSelect
                variableActions={variableSearchResActions}
            />

        </InnerPage>
    
    )
}
