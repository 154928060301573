//import PropTypes from "prop-types"

import { IconButton } from "../Buttons"
import { LoadingWrapper } from "../GeneralUI"
import { /*variableShape, */variableFieldDisplay } from "../../utils"


const variableFieldNames = [
    'observations',
    'fullquestion',
    'answerandtype',
    'topanswers',
    'validationregex',
    'literalvalues',
    'contributors',
    'customvariable',
]

export const VariableDetailsTable = ({ removeVariable, variables, countsLoading }) => {
    
    const variableFields = variableFieldNames.map(
            field => variableFieldDisplay[field]);
    
    return (
        <div className="table-scroll">
            <div className="table-scroll__wrapper">
                <table className="w-100 table table--variable-details table--row-headers" >

                    {/* Table Header */}
                    <thead className="w-100">
                        <tr className="w-100">

                            {/* Empty cell in corner */}
                            <th className="table__blank-cell w-25" ></th>

                            {/* Table header cells -> variable names */}
                            {
                                variables.map((variable, i) => (

                                    <th key={i} className={`h5`}>
                                        <div className="d-flex align-items-center">
                                            {/* Variable name */}
                                            {variable.variable_name}

                                            {/* Delete button */}
                                            {
                                                // Allow variables to be deleted from comparison table if more than one
                                                variables.length > 1 && removeVariable &&
                                                
                                                <span className="ml-auto">

                                                    <IconButton
                                                        onClick={() => removeVariable(variable)}
                                                        classes="mt-2 position-static"
                                                        iconName="trash-alt" 
                                                        tooltip="Remove Variable"
                                                    />
                                                    
                                                </span>
                                            }
                                        </div>
                                    </th>

                                ))
                            }
                            
                        </tr>
                    </thead>
                    
                    {/* Table Body */}
                    <tbody>
                        {
                            variableFields.map((row, i) => (
                                <tr key={i}>

                                    {/* Row header */}
                                    <th>{variableFields[i].fieldname}</th>

                                    {/* Variable Data */}
                                    {
                                        variables.map((v, j) => (
                                            <td key={j}>{row.isCount ?
                                                        <LoadingWrapper
                                                            loading={countsLoading}
                                                            smallText
                                                        >
                                                           {row.xform(v)}
                                                        </LoadingWrapper>
                                                        : 
                                                        row.xform(v)}</td>
                                        ))
                                    }
                                    
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

/*VariableDetailsTable.propTypes = {
    removeVariable: PropTypes.func,
    variables: PropTypes.arrayOf(PropTypes.shape(variableShape)),
    countsLoading: PropTypes.bool
}*/
