import { Link } from "react-router-dom"
//import PropTypes from "prop-types"

import { Count } from "../Typography"
import api from '../../utils/api'
import { ActionButton, DeleteButton } from "../Buttons"

export const VariableSetsTable = ({ actions = [], variableSets, noDelete }) => {
    
    return (
        <table className="w-100 mb-0">

            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>

                    {actions && actions.length > 0 &&
                        <th>Actions</th>
                    }
                </tr>
            </thead>

            <tbody>
                
                {
                    variableSets.map((set, i) => (
                        <tr key={i}>

                            {/* Name  */}
                            <td>        
                                <Link to={`/variable-sets/${set.id}`}>{set.name}</Link> <Count>{set.variables.length}</Count>
                            </td>

                            {/* Description */}
                            <td>
                                {set.description}
                            </td>

                            {/* Actions */}
                                <td>
                                    
                                    { noDelete || 
                                    <DeleteButton
                                        id={set.id}
                                        entityLabel="Variable Set"
                                        deleteFn={api.deleteVariableSet}
                                        modal={{
                                            title: "Delete Variable Set?",
                                            body: "Please confirm that you’d like to delete this Variable Set."
                                        }}
                                    />}
                            
                                    { actions.map((action, i) => (
                                        <ActionButton
                                            key={i}
                                            entity={set}
                                            entityId={set.id}
                                            action={action}
                                        />
                                    ))}
                                
                                </td>
                        </tr>
                    ))
                }

            </tbody>

        </table>
    )

}

/*VariableSetsTable.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object),
    variableSets: PropTypes.arrayOf(PropTypes.object),
    noDelete: PropTypes.bool
}*/
