import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { InnerPage } from '../../layout'
import { CohortTree } from '../../components/Cohorts';
import { getPathFromRouteName, parseCohortListToTree } from '../../utils';
import api from '../../utils/api';
import { ASCERTAIN_PEDIGREE, COHORT_LOGIC, COMPOUND_COHORT, HOME, SUBSET_COHORT, VIEW_COHORT } from '../../utils/constants';
import { Alert, Spacer } from '../../components/GeneralUI';
import { useAppState/*, useCancelAsyncCall*/ } from '../../hooks';


export const AllCohorts = () => {

    const [ cohorts, setCohorts ] = useState(null)
    const [ loading, setLoading ] = useState(true)

    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText } = useAppState();

    const cohortActions = {
        primary: [
            {
                name: 'pedigree',
                to: getPathFromRouteName(ASCERTAIN_PEDIGREE),
                tooltip: 'Ascertain Pedigrees',
                iconName: 'pedigree',
                variant: 'secondary',
                fontAwesome: false,
            },
            {
                name: 'compound',
                to: getPathFromRouteName(COMPOUND_COHORT),
                tooltip: 'Create Compound Set',
                iconName: 'combine-cohort',
                variant: 'secondary',
                fontAwesome: false,
            },
            {
                name: 'subset',
                to: getPathFromRouteName(SUBSET_COHORT),
                tooltip: 'Create Subset',
                iconName: 'plus-circle',
                variant: 'secondary'
            },
        ],
        secondary: [
            {
                name: 'logic',
                to: getPathFromRouteName(COHORT_LOGIC),
                tooltip: 'View Logic',
                iconName: 'expanded-detail',
                fontAwesome: false,
                newTab: true,
            },
            {
                name: 'view',
                to: getPathFromRouteName(VIEW_COHORT),
                tooltip: 'View Details',
                iconName: 'table',
                newTab: true,
            }
        ]
    };

    useEffect(() => {
        api.getAllCohorts()
            .then(res => {
                //if (cancelAsyncCall()) return null;
                const cohorts = res.data.cohorts;
   
                let parsedCohorts;
                try {
                    parsedCohorts = parseCohortListToTree(cohorts);
                } catch(e) {
                    console.log(e)
                }
  
                setCohorts(parsedCohorts);
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get Cohorts.'))
            })
            .finally(() => setLoading(false))

    }, [/*cancelAsyncCall, */errorCheckText])

    return (

        <InnerPage
            loading={loading}
            title="Build a Cohort"
            intro="Cohorts are sets of individuals of interest to your study. They are primarily defined by creating a subset of All Individuals (and/or subsets of those subsets), although cohorts can also be combined or subtracted or intersected with eachother for studies across multiple conditions."
        >

            {!cohorts || cohorts.length === 0 ? 
                <Alert variant="warning">

                    A problem has occurred and no cohorts could be loaded. Please try again later.

                    <Spacer size={2} />

                    <Link to={getPathFromRouteName(HOME)}>Return to the DIVER Dashboard.</Link>

                </Alert>
            :
                
                <CohortTree 
                    cohorts={cohorts} 
                    cohortActions={cohortActions} 
                />
            }
        
        </InnerPage>
    )
}
