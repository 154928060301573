import { useCallback, useEffect, useState } from 'react';
//import PropTypes from 'prop-types';

import { Button } from '../Buttons';
import { Select, TextInput } from '../FormElements';
import { Icon } from '../GeneralUI'
import api from '../../utils/api';
import { useNavigate, useLocation } from 'react-router';
import { getPathFromRouteName } from '../../utils';
import { ASCERTAIN_PEDIGREE, SUBSET_COHORT } from '../../utils/constants';
//import { useCancelAsyncCall } from '../../hooks';

/**
 * Form for collecting name/description for creating/editing cohorts
 * Also displays parent set for Subset and Pedigree Set cohorts
 */
export const CohortForm = ({
    description: _description,
    disableParentSet,
    name: _name, 
    noParent,
    onReset,
    onSubmit,
    parentId = 1, 
    setName: _setName, 
    setDescription: _setDescription
}) => {
    

    const [name, setName] = useState(_name || '')
    const [description, setDescription] = useState(_description || '')
    
    const [allCohorts, setAllCohorts] = useState([])
    
    const navigate = useNavigate()
    const location = useLocation();

    //const cancelAsyncCall = useCancelAsyncCall();

    // keep parent in sync if name comes from parent
    useEffect(() => {

        if (_name)
            setName(_name)
            
    }, [_name])

    // keep parent in sync if description comes from parent
    useEffect(() => {

        if (_description)
            setDescription(_description)

    }, [_description])


    // Change the Router param when Select changes; keeps parent id in sync
    const handleParentIdChange = useCallback((newParentId) => {

        if (location.pathname.indexOf('cohorts/subset') > -1) {

            navigate(getPathFromRouteName(SUBSET_COHORT, {id: newParentId}))

        } else if (location.pathname.indexOf('cohorts/pedigree') > -1) {
            
            navigate(getPathFromRouteName(ASCERTAIN_PEDIGREE, {id: newParentId}))
        
        } 

    }, [navigate, location.pathname])

    useEffect(() => {
        handleParentIdChange(parentId)
    }, [ handleParentIdChange, parentId ])

    // Get all cohorts to populate Select field
    useEffect(() => {
        api.getAllCohorts()
            .then(res => {
                //if (cancelAsyncCall()) return null
   
                const cohorts = res.data.cohorts;
                const cohortOptions = cohorts.map(cohort => ({
                    name: cohort.name,
                    value: cohort.cohortid.toString()
                }))
  
                setAllCohorts(cohortOptions);
            })
            .catch(err => {
                // FIXME: should this be toasted?
                console.log(err.message)
            })
    }, [/*cancelAsyncCall,*/ setAllCohorts])


    // Set back to defaults on reset
    const handleReset = e => {
        e.preventDefault();

        setName(_name || '');
        setDescription(_description || '');

        if (onReset)
            onReset();        
    }

    // Prevent refresh and pass values to parent on submit
    const handleSubmit = e => {
        e.preventDefault();
        
        if (onSubmit){
            onSubmit({
                name,
                description
            });
        } 
    }

    // Set either parent or child "name" value depending on if value is controlled by parent
    const handleNameChange = useCallback((val) => {
        if (_setName) {
            _setName(val)
        }
        else {
            setName(val)
        }
    }, [ _setName ])
    
    // Set either parent or child "description" value depending on if value is controlled by parent
    const handleDescriptionChange = (val) => {
        if (_setDescription) {
            _setDescription(val)
        }
        else {
            setDescription(val)
        }
    }

    // Determine whether value comes from parent or child and return it
    const parentOrChildValue = (pVal, cVal) => {
        if (typeof pVal === 'undefined') {
            return cVal
        }
        return pVal;
    }

    const validateName = useCallback(() => {
        if (allCohorts.find(c => c.name === name)) {
            return "A cohort with this name already exists. Please choose a different name."
        }
    }, [ allCohorts, name ])
    
    return (
         
        <section className="section--blue">

            <form onSubmit={handleSubmit} onReset={handleReset}>
                
                <div>
                    
                    {/* Parent Cohort */}
                    { allCohorts && allCohorts.length && !noParent ? 
                        <Select
                            name="parent"
                            value={parentId || 0}        
                            label="Parent Cohort"
                            options={allCohorts}
                            onChange={handleParentIdChange}
                            disabled={disableParentSet}
                        /> 
                    : '' }
                    
                    {/* Cohort Name */}
                    <TextInput 
                        name="title"
                        value={parentOrChildValue(_name, name)}
                        onChange={handleNameChange}
                        label="Name"
                        fieldType="cohort_name"
                        required
                        validate={validateName}
                    /> 

                    {/* Description */}
                    <TextInput
                        name="description"
                        value={parentOrChildValue(_description, description)}
                        onChange={handleDescriptionChange}
                        label="Description"
                        textarea
                    /> 

                </div>

                { (onReset || onSubmit) &&

                    <div className="d-flex mt-4 justify-content-end align-items-center">

                        { onReset &&
                            <Button
                                size="xs"
                                variant="primary"
                                classes="font-weight-600"
                                uppercase
                                type="reset"
                            >
                                Cancel
                            </Button>
                        }

                        {onReset && onSubmit &&
                            <span className="p-1"></span>
                        }

                        { onSubmit &&

                            <Button 
                                size="md" 
                                variant="secondary"
                                classes="font-weight-600"
                                uppercase
                                type="submit"
                            >
                                Save Details <Icon name="check-circle" />
                            </Button>
                        }

                    </div>
                }

            </form>

        </section>
    )
}


/*CohortForm.propTypes = {
    disableParentSet: PropTypes.bool,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    noParent: PropTypes.bool,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    parentId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    setDescription: PropTypes.func,
    setName: PropTypes.func,
}*/
