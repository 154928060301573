import { useState } from "react"
//import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { Button, IconButton } from "."
import { Modal } from "../GeneralUI/Modal";
import { useAppState } from "../../hooks";

/**
 * Copy button + modal for selecting target user
 */
export const CopyButton = ({ copyFn, entityLabel, id, targetParam, modal }) => {

    const [ modalOpen, setModalOpen ] = useState(false);
    const navigate = useNavigate()
    const { errorCheckText } = useAppState();

    const confirmCopy = () => {
      
        setModalOpen(false)

        if (id && copyFn && targetParam) {
            
            copyFn({
                    construct_id: id,
                    target_user: targetParam
                })
                .then(res => {
                    navigate(0)
                })
                .catch(err => 
                    toast.error(errorCheckText(err, `Unable to copy ${entityLabel}.`))    
                )
        }
    }


    return (
        <>

            <IconButton 
                iconName="copy"
                onClick={() =>  setModalOpen(true)} 
                tooltip={`Copy ${entityLabel}`}
                variant="outline"
            />

            {modalOpen &&
                <Modal
                    title={modal.title}
                    handleClose={() => setModalOpen(false)}
                    action={
                        <>
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={() => setModalOpen(false)}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="secondary"
                                onClick={confirmCopy}
                                disabled={modal.button ? modal.button.disabled : false}
                            >
                                Copy
                            </Button>
                        </>
                    }
                >

                    {modal.body}

                </Modal>
            }

        </>
    )
}


/*CopyButton.propTypes = {
    entityLabel: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    targetParam: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    message: PropTypes.node,
}*/
